// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-lost-password-js": () => import("./../../../src/pages/lost-password.js" /* webpackChunkName: "component---src-pages-lost-password-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-update-password-js": () => import("./../../../src/pages/update-password.js" /* webpackChunkName: "component---src-pages-update-password-js" */),
  "component---src-pages-wp-member-js": () => import("./../../../src/pages/WpMember.js" /* webpackChunkName: "component---src-pages-wp-member-js" */),
  "component---src-pages-wp-page-js": () => import("./../../../src/pages/WpPage.js" /* webpackChunkName: "component---src-pages-wp-page-js" */),
  "component---src-pages-wp-post-js": () => import("./../../../src/pages/WpPost.js" /* webpackChunkName: "component---src-pages-wp-post-js" */)
}

